<template>
    <CardsContacts/>
</template>

<script>
// @ is an alias to /src
import CardsContacts from '@/components/CardsContacts.vue'

export default {
  name: 'ListeContacts',
  components: {
    CardsContacts
  }
}
</script>
