<template>
    <v-container fluid>
        <v-row>
          <v-col cols="12" :md="4">
            <v-select
                :items="status_items"
                item-text="title"
                item-value="id"
                multiple
                v-model="selectedStatus"
                v-on:blur="reloadList()"
                label="Statut du dossier"
            ></v-select>
          </v-col>
          <v-col cols="12" :md="4">
            <v-select
                :items="typebiens_items"
                item-text="title"
                item-value="id"
                multiple
                v-model="selectedTypeBiens"
                v-on:blur="reloadList()"
                label="Type de bien"
            ></v-select>
          </v-col>
          <v-col cols="12" :md="4">
            <v-select
                :items="typeoffre_items"
                item-text="title"
                item-value="id"
                multiple
                v-model="selectedOffres"
                v-on:blur="reloadList()"
                label="Type d'offre"
            ></v-select>
          </v-col>
        </v-row>
      <v-row>
        <v-col>
          <v-text-field
            label="Prix "
            hide-details="auto"></v-text-field>
        </v-col>

        <v-col>
          <v-text-field
              label="Surface "
              hide-details="auto"></v-text-field>
        </v-col>
      </v-row>
        <v-row >
          <v-col align="center" v-if="isloading">
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row v-if="!isloading">
          <v-col
              v-for="item in items"
              :key="item.id"

              :md="6"
              :lg="3"



          >
            <v-card
                :loading="loading"
                class="mx-auto"

            >
              <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
              </template>

              <v-card-title class="pa-3">
                <v-avatar size="32" class="mr-2">
                  <img
                      alt="user"
                      :src="`https://logiciel.foxdiffusion.com/img/photosUsers/${item.negociateur_image}`"
                  >
                </v-avatar>
                <span>{{item.negociateur}}</span>
              </v-card-title>

              <v-img

                  max-with="100%"
                  max-height="295px"
                  :src="`https://logiciel.foxdiffusion.com/img/biens/thumbs/285x215/${item.vignette}`"
              >



              </v-img>
              <v-card-title class="pb-1">
                <div class="text-no-wrap overflow-hidden"><i class="fas fa-1x fa-map-marker-alt mr-1 mb-1"></i>{{item.ville_diffusee}}</div>
              </v-card-title>


              <v-card-text>

                <div class="title pb-1">
                  <i class="fas fa-heart red--text mr-1" v-if="item.an_coupCoeur"></i><span v-if="item.an_prix">{{item.an_prix}}  €</span><span v-else>Prix non reseigné</span>
                </div>


                <v-chip
                    small
                    class="mr-2"
                    color="blue"
                    label
                    text-color="white"
                >
                  {{item.transaction}}
                </v-chip>
                <v-chip
                    small
                    class="mr-2"
                    color="green"
                    label
                    text-color="white"
                >
                  {{item.bien}}
                </v-chip>


                <v-row class="my-1">
                  <v-col :cols="7" ><i class="fas fa-share-square" v-if="item.partage"></i> {{item.ref_annonce}}</v-col>

                </v-row>

                <v-btn
                    small
                    class="mr-2"
                    outlined
                    color="indigo"
                >
                  <i class="fas fa-eye"></i>
                </v-btn>


                <v-btn
                    small
                    class="ma-2"
                    outlined
                    color="indigo"
                >
                  <router-link to="/recherche"><i class="fas fa-pen"></i></router-link>
                </v-btn>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
//import axios from 'axios'
import ApiService from "@/api/api.service";
export default {
data: () => ({
  isloading:1,
  status_items: [{id:1, title:"Disponible"},{id:2, title:"Suspendu"}],
  typeoffre_items: [{id:1, title:"Vente"},{id:2, title:"Location"}],
  typebiens_items:[{id:1, title:" Appartement"},{id:2, title:"Maison"}],
    /*items: [
      {id:1,title:"Non renseigné",price:"365 000", reference:"A59497 - n°888",coupdecoeur:1,img:"538179177_5f86f3d14f5c1.jpg",transaction:"Vente",bien:"Maison"},
      {id:2,title:"Non renseigné",price:"628 000", reference:"A59294 - n°929",coupdecoeur:0,img:"799259274_5f5fb30318a15.jpg",transaction:"Vente",bien:"Maison"},
      {id:3,title:"Saint-Germain-de-Tallevende-la-Lande-Vaumont",price:"170 000", reference:"B58352",coupdecoeur:1,img:"538179177_5f86f3d14f5c1.jpg",transaction:"Vente",bien:"Maison"},
      {id:4,title:"Lavérune",price:"170 000", reference:"B58352",coupdecoeur:0,img:"538179177_5f86f3d14f5c1.jpg",transaction:"Location",bien:"Appartement"},
      {id:5,title:"Lavérune",price:"170 000", reference:"B58352 - n°767",coupdecoeur:1,img:"538179177_5f86f3d14f5c1.jpg",transaction:"Vente",bien:"Maison"},
    ]*/
  selectedStatus:[1],
  selectedOffres:[],
  selectedTypeBiens:[],
    items:[]

  }),
  mounted () {

    this.reloadList();
  },
  methods: {
    reloadList() {
      //alert('totot')
      this.isloading = 1;
      ApiService.setHeader();
      ApiService.get("api", "recherches?statut="+this.selectedStatus+"&type_offre="+this.selectedOffres+"&type_bien="+this.selectedTypeBiens)
          .then(({data}) => {
                this.items = data
                this.isloading = 0;
          }
          )

      /*axios
          .get(process.env.VUE_APP_API_URL+"annonces?statut="+this.selectedStatus+"&type_offre="+this.selectedOffres+"&type_bien="+this.selectedTypeBiens)
          .then(response => {
            //this.wholeResponse = response.data.Search
            this.items = response.data
            this.isloading = 0;
          })
          .catch(error => {
            console.log(error)
          })*/
    }
  }
}

</script>

<style scoped>

</style>