<template>
    <v-container fluid>
      <v-row>
      <v-col><h1>Liste de mes contacts</h1></v-col>
      </v-row>
      <v-row>
        <v-col cols="12" :sm="6">

          <v-text-field
            outlined
            label="Rechercher"
            placeholder="Rechercher par un nom, un numéro de téléphone, un mail"
            name="term"
            v-on:click:clear="clearAndReload"
            v-on:keyup.enter="updateAndReload('term',$event.target.value)"
            v-on:blur="updateAndReload('term',$event.target.value)"
            clearable
            hide-details="auto"
            ></v-text-field>

        </v-col>
        <v-col cols="12" :sm="6">

          <v-select
              outlined
              hide-details="auto"
              :items="limit_by"
              item-text="title"
              item-value="id"
              :value="limit"
              @change="updateAndReload('limit',$event)"
              label="Type"
          ></v-select>

        </v-col>
      </v-row>
        <v-row v-if="isloading">
          <v-col align="center" >
            <v-progress-circular
                indeterminate
                color="primary"
            ></v-progress-circular>
          </v-col>
        </v-row>
        <v-row v-if="!isloading">
          <v-col
              v-for="item in items"
              :key="item.id"
              cols="12"
              :md="6"
              :lg="3"
          >
            <v-card
                class="mx-auto"

            >
              <template slot="progress">
                <v-progress-linear
                    color="deep-purple"
                    height="10"
                    indeterminate
                ></v-progress-linear>
              </template>

              <v-card-title class="pa-0 pt-3 pl-3 pb-3 pr-3">
                <div class="ellipsis_title"><v-icon small class="mr-2 mb-1">fas fa-user</v-icon>{{item.pe_prenom}} {{item.pe_nom}}</div>
              </v-card-title>
              <v-card-text class="pa-0 pl-3 pb-2">
                <v-icon small class="mr-2">fas fa-map-marker-alt</v-icon>
              </v-card-text>
              <v-card-text class="pa-0 pl-3 pb-2">
                <v-icon small class="mr-2">fas fa-phone</v-icon>{{item.pe_telephone}}
              </v-card-text>
              <v-card-text class="pa-0 pl-3 pb-2">
                <v-icon small class="mr-2">fas fa-phone</v-icon>{{item.pe_portable}}
              </v-card-text>

              <v-card-text class="pa-0 pl-3 pb-2">
                <v-icon small class="mr-2">fas fa-phone</v-icon>{{item.pe_autreTel}}
              </v-card-text>
              <v-card-text class="pa-0 pl-3 pb-2">
                <v-icon small class="mr-2">fas fa-envelope</v-icon>{{item.pe_email}}
              </v-card-text>


              <v-card-text>

                <v-btn
                    small
                    outlined
                    color="indigo"
                >
                  <router-link to="/contact"><i class="fas fa-pen"></i></router-link>
                </v-btn>

              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
</template>

<script>
//import axios from 'axios'
import ApiService from "@/api/api.service";
export default {
data: () => ({
  isloading:1,
  limit_by:[{id:1, title:" Tout"},{id:2, title:"Vendeur"},{id:3, title:"Acquéreur"}],
  limit:1,
  term:""
  }),
  mounted () {

    this.reloadList();
  },
  methods: {

    updateAndReload(name, value) {
       if (this[name]!=value) {
          this[name]=value;
          this.reloadList();
       }
    },
    reloadList() {

      this.isloading = 1;
      ApiService.setHeader();
      ApiService.get("api", "contacts?term="+(this.term!=null?this.term:"")+"&limit_by="+(this.limit!=null?this.limit:""))
          .then(({data}) => {
                this.items = data
                this.isloading = 0;
          }
          )

    },
    clearAndReload () {
      this.term = ''
      this.reloadList();
    }
  }
}

</script>

<style scoped>
.ellipsis_title {
  text-overflow: ellipsis;overflow:hidden;white-space: nowrap
}

</style>