<template>
<div>Deconnexion en cours...</div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Logout',
  components: {

  },
  mounted() {
    this.$store
        .dispatch("auth/logout")
        .then(() => this.$router.replace("/login"));
  }
}
</script>
