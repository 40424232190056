<template>
<div>
  <h1 class="d-flex justify-center">Drive FOX</h1>
  <div class="d-flex justify-center">

      <v-card class="pa-8">
  <form @submit.prevent="submit">

      <v-text-field
          v-model="username"
          :error-messages="errors"
          label="Identifiant"
      ></v-text-field>

      <v-text-field
          v-model="password"
          :error-messages="errors"
          label="Mot de passe"
          type="password"
      ></v-text-field>


    <v-btn
        class="mr-4"
        @click="signIn"
    >
      Connexion
    </v-btn>
  </form>
      </v-card>

</div>
</div>

</template>

<script>
import {mapGetters} from 'vuex';
export default {

  data: () => ({
    username:null,
    password:null,
    errors:null,
  }),
  computed: {
    ...mapGetters("auth", {
      loginErrors: "getLoginErrors"
    })
  },
  methods: {
    signIn() {
      const formData = new FormData();
      formData.append('grant_type', "password");

      formData.append('client_id', process.env.VUE_APP_CLIENT_ID);
      formData.append('client_secret', process.env.VUE_APP_CLIENT_SECRET);

      formData.append('username', this.username);
      formData.append('password', this.password);

      this.$store
          .dispatch("auth/login", formData)
          .then(() => {
                this.$store
                    .dispatch("auth/user").then(
                    () => this.$router.replace("home")
                )
              }
          );
    }
  }
}
</script>

<style scoped>

</style>