<template>
  <v-container fluid>
      <v-tabs
          v-model="tab"


      >
        <v-tab
            :key="TAB1"
        >
          1 - Dossier
        </v-tab>
        <v-tab
            :key="TAB2"
        >
          2 - Composition
        </v-tab>
        <v-tab
            :key="TAB3"
        >
          3 - Prix & autres informations
        </v-tab>
        <v-tab
            :key="TAB4"
        >
          4 - Médias & documents
        </v-tab>
        <v-tab
            :key="TAB5"
        >
          5 - Texte & diffusion annonce
        </v-tab>
        <v-tab
            :key="TAB6"
        >
          6 - Fiches PDF
        </v-tab>
        <v-tab
            :key="TAB7"
        >
          7 - Rapprochement
        </v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item
            :key="TAB1"
        >
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
              <v-text-field
                  label="Identifiant"
                  :rules="rules"
                  hide-details="auto"
              ><v-icon slot="prepend">fas fa-user</v-icon></v-text-field>
              <v-text-field
                  label="Nom"
                  :rules="rules"
                  hide-details="auto"
                  class="mt-4"
              ></v-text-field>
              <v-text-field
                  label="Prénom"
                  :rules="rules"
                  hide-details="auto"
                  class="mt-4"
              ></v-text-field>

                  <v-menu
                      ref="menu"
                      v-model="menu"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="date"
                          label="Date de naissance"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        ref="picker"
                        v-model="date"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                    ></v-date-picker>
                  </v-menu>

              <v-text-field
                  label="Téléphone"
                  :rules="rules"
                  hide-details="auto"
              >
                <v-icon slot="prepend">fas fa-phone</v-icon>

              </v-text-field>
                  <v-text-field
                      label="Adresse électronique"
                      :rules="rules"
                      hide-details="auto"
                      class="mt-4"
                  >
                    <v-icon slot="prepend">fas fa-envelope</v-icon>

                  </v-text-field>
                  <v-text-field
                      label="Adresse Gmail"
                      :rules="rules"
                      hide-details="auto"
                      class="mt-4"
                  >
                    <v-icon slot="prepend">fab fa-google</v-icon>

                  </v-text-field>

                  <v-textarea
                      outlined
                      name="input-7-4"
                      label="Présentation"
                      value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                      class="mt-8"
                  ></v-textarea>

                  <v-textarea
                      outlined
                      name="input-7-4"
                      label="Honoraires"
                      value="The Woodman set to work at once, and so sharp was his axe that the tree was soon chopped nearly through."
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item
            :key="TAB2"
        >
          <v-card flat>
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
              <v-text-field
                  label="RCS / RSAC"
                  :rules="rules"
                  hide-details="auto"

              >

              </v-text-field>
              <v-text-field
                  label="SIRET"
                  :rules="rules"
                  hide-details="auto"
                  class="mt-4"
              >

              </v-text-field>
                  <v-select
                      :items="formes_juridiques"
                      label="Forme juridique"
                      class="mt-4"
                  ></v-select>
                  <v-text-field
                      label="Nom compagnie d'assurance"
                      :rules="rules"
                      hide-details="auto"

                  >
                  </v-text-field>
                  <v-text-field
                      label="Numéro de contract RCP"
                      :rules="rules"
                      hide-details="auto"
                      class="mt-4"
                  >

                  </v-text-field>

                  <v-menu
                      ref="menu-rcp"
                      v-model="menu_rcp"
                      :close-on-content-click="false"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          v-model="date_rcp"
                          label="Date de la RCP"
                          prepend-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          class="mt-4"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        ref="picker"
                        v-model="date_rcp"
                        :max="new Date().toISOString().substr(0, 10)"
                        min="1950-01-01"
                        @change="save"
                    ></v-date-picker>
                  </v-menu>
                  <v-text-field
                      label="Comission"
                      :rules="rules"
                      hide-details="auto"
                      class="mt-4"
                  >

                  </v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
  </v-container>
</template>

<script>
export default {
  data () {
    return {
      formes_juridiques: ["totot","ttiti"],
      tab: null,
      date: null,
      date_rcp: null,
      menu: false,
      menu_rcp: false
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
  },
}
</script>