<template>
    <CardsBiens/>
</template>

<script>
// @ is an alias to /src
import CardsBiens from '@/components/CardsBiens.vue'

export default {
  name: 'ListeBiens',
  components: {
    CardsBiens
  }
}
</script>
