<template>
  <v-container fluid>
    <v-snackbar
        v-model="snackbar"
        :multi-line="multiLine"
        top
    >
      {{ snack_text }}

    </v-snackbar>



          <v-card flat id="profile-card">
            <v-card-text>
              <v-row>
                <v-col cols="12" sm="6">
              <v-text-field
                  label="Nom"
                  :value="nom"
                  hide-details="auto"
                  class="mt-4"
                  disabled
              ></v-text-field>
              <v-text-field

                  label="Prénom"
                  :value="prenom"
                  hide-details="auto"
                  class="mt-4"
                  disabled
              ></v-text-field>


              <v-text-field

                  class="mt-4"
                  :value="telephone"
                  label="Téléphone"
                  prepend-inner-icon="fas fa-phone"
                  disabled
              >


              </v-text-field>
                  <v-text-field
                      label="Adresse électronique"
                      :value="email"
                      hide-details="auto"
                      class="mt-4"
                      disabled
                  >
                    <v-icon slot="prepend">fas fa-envelope</v-icon>

                  </v-text-field>


                  <v-textarea
                      outlined
                      name="input-7-4"
                      label="Présentation"
                      v-model="presentation"
                      class="mt-8"
                      disabled
                  ></v-textarea>

                  <v-textarea
                      outlined
                      name="input-7-4"
                      label="Honoraires"
                      v-model="honoraires"
                      disabled
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>

  </v-container>
</template>

<script>
import ApiService from "@/api/api.service";

export default {
  props: {
    id: {
      type: String,
      default: 'Vue!'
    }
  },
  data () {
    return {
      snackbar:false,
      snack_text:null,
      profil_id:null,
      formes_juridiques: ["totot","ttiti"],
      tab: null,
      date: null,
      date_rcp: null,
      menu: false,
      menu_rcp: false,
      identifiant:null,
      nom:null,
      prenom:null,
      date_de_naissance:null,
      telephone:null,
      email:null,
      gmail:null,
      presentation:null,
      honoraires:null,
    }
  },
  watch: {
    menu (val) {
      val && setTimeout(() => (this.$refs.picker.activePicker = 'YEAR'))
    },
  },
  methods: {
    save (date) {
      this.$refs.menu.save(date)
    },
    autosave (event) {
      this.snackbar = true
      this.snack_text = "Sauvegarde en cours";
      const formData = new FormData();
      formData.append('data[0][name]', event.target.getAttribute('data-name'));
      formData.append('data[0][id]', event.target.getAttribute('data-id'));
      formData.append('data[0][value]', event.target.value);
      ApiService.setHeader();
      ApiService.post("api/autosave", formData)
          .then(({data}) => {
            this.snackbar = false
            console.log(data);
          })
      console.log(event.target.getAttribute('data-id'))
    }
  },
  mounted () {
    ApiService.setHeader();
    ApiService.get("api", "user")
        .then(({data}) => {
          this.profil_id = data.ut_id
           this.identifiant = data.ut_login
           this.prenom = data.ut_prenom
           this.nom = data.ut_nom
          this.telephone = data.ut_telephone
          this.email = data.ut_adresseMail
          this.gmail = data.gmail_account
          this.presentation = data.ut_presentation
          this.honoraires = data.ut_infos
        })
        .catch(({response}) => {
          response
          /*context.commit(
              "setError",
              {target: 'login', message: response.data.error}
          );*/

        });

  }
}
</script>

<style>
#profile-card .theme--light.v-label--is-disabled, #profile-card .theme--light.v-input--is-disabled input, #profile-card .theme--light.v-input--is-disabled textarea{
  color: black !important;
}
</style>