<template>
  <v-container fluid>
    <v-text-field
    :value="sujet"
    label="Objet du mail"
    placeholder="Objet du mail"
    class="mt-4"
    ></v-text-field>
    <v-textarea
        outlined
        name="input-7-4"
        label="Contenu du mail"
        placeholder="Contenu du mail"
        :value="body"
        class="mt-8"
    ></v-textarea>
  </v-container>

</template>

<script>


export default {
  name: "SendMail",
  components: {

  },
  data(){
    return {
      body:null,
      sujet:null
    };
  },
}
</script>

