<template>
  <AnnonceForm/>
</template>

<script>
// @ is an alias to /src
import AnnonceForm from '@/components/AnnonceForm.vue'

export default {
  name: 'MyAnnonce',
  components: {
    AnnonceForm
  }
}
</script>
