<template>
  <v-container fluid>
    <h1>Agenda</h1>
    <iframe :src="`https://calendar.google.com/calendar/embed?src=${gmail}&amp;ctz=Europe%2FParis`" style="border: 0" scrolling="no" width="800" height="600" frameborder="0"></iframe>
  </v-container>
</template>

<script>


export default {
  name: "Agenda",
  components: {

  },
  data(){
    return {
      gmail:null

    };
  },
  mounted() {
    this.gmail = this.$store.getters["auth/currentGmail"]
  }
}
</script>

