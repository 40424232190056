<template>
  <v-container fluid>
    <vue-good-table
        :columns="columns"
        :rows="rows"
        :group-options="{
          enabled: true
        }"
        :search-options="{
          enabled: true
        }"
        styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <router-link :to="`/edit-profile/${props.row.action}`"><v-icon small>fas fa-pen</v-icon></router-link>
        </span>
        <span v-else>
          {{props.formattedRow[props.column.field]}}
        </span>
      </template>
    </vue-good-table>
  </v-container>
</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';
import ApiService from "@/api/api.service";

export default {
  name: "MyBrand",
  components: {
    VueGoodTable,
  },
  data(){
    return {
      columns: [
        {
          label: 'Nom de la société / négociateur',
          field: 'name',
        },
        {
          label: 'Téléphone',
          field: 'telephone',
          type: 'number',
          tdClass: 'text-left',
          thClass: 'text-left',
        },
        {
          label: 'Mail',
          field: 'mail',
        },
        {
          label: 'Rang',
          field: 'createdAt',
        },
        {
          label: 'Action',
          field: 'action',
          tdClass: 'text-center',
          thClass: 'text-center',
          sortable:false,
        },
      ],
      rows: [],

    };
  },
  mounted () {

    ApiService.setHeader();
    ApiService.get("api", "enseigne")
        .then(({data}) => {
          var myrows = [];
          for (var i in data) {
            var negos = [];
            for (var nego in data[i]['negociateurs']) {
               var current_nego = {
                 name: data[i]['negociateurs'][nego]['ut_prenom']+ " "+ data[i]['negociateurs'][nego]['ut_nom'],
                 mail: data[i]['negociateurs'][nego]['ut_adresseMail'],
                 telephone: data[i]['negociateurs'][nego]['ut_telephone'],
                 action: data[i]['negociateurs'][nego]['ut_id']
               }
              negos.push(current_nego);
            }
            var current_row = {
              mode: 'span', // span means this header will span all columns
              label: data[i]['so_nom'], // this is the label that'll be used for the header
              html: false, // if this is true, label will be rendered as html
              children: negos
            }
            myrows.push(current_row)
          }
          //alert(myrows)
          this.rows = myrows;
        })
        .catch(({response}) => {
          response

        });

  }
}
</script>

