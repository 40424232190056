<template>
  <div>
  <v-navigation-drawer
      v-model="drawer"
      app
  >
    <template v-slot:prepend>
      <v-list-item two-line>
        <v-list-item-avatar >
          <img style="border: #AAA 1px solid;" :src="nego_img">
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title >{{prenom}} {{nom}}</v-list-item-title>
          <v-list-item-subtitle></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </template>

    <v-divider></v-divider>

    <v-list dense>
      <v-list-item v-if="rang==='3'">
        <v-list-item-icon>
          <v-icon small>far fa-building</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/enseigne"><v-list-item-title>Mon enseigne</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-home</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/mon-agence"><v-list-item-title>Mon agence</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-user</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/my-profile"><v-list-item-title>Mon profil</v-list-item-title></router-link>
        </v-list-item-content>
        <v-list-item-icon v-if="rang>1">
          <router-link to="/edit-my-profile"><v-icon small>fas fa-pen</v-icon></router-link>
        </v-list-item-icon>
      </v-list-item>
    </v-list>

    <v-divider class="ml-8 mr-8"></v-divider>

    <v-list dense>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-city</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/liste-biens"><v-list-item-title>Liste des biens</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-search</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/liste-recherches"><v-list-item-title>Liste des recherches</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-users</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/liste-contacts"><v-list-item-title>Mes contacts</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-envelope</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/envoyer-mail"><v-list-item-title>Envoyer un mail</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-calendar-alt</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/agenda"><v-list-item-title>Agenda</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
      <v-list-group>
        <v-icon slot="prependIcon" small>fas fa-chart-area</v-icon>

        <template v-slot:activator>
          <v-list-item-title>Rapports</v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-content>Rapport mensuel agence</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>Activité logicielle</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>Diffusion</v-list-item-content>
        </v-list-item>

      </v-list-group>
      <v-list-group v-if="rang>1">
        <v-icon slot="prependIcon" small>fas fa-shopping-cart</v-icon>

        <template v-slot:activator>
          <v-list-item-title>Boutique</v-list-item-title>
        </template>
        <v-list-item>
          <v-list-item-content>Annonce</v-list-item-content>
        </v-list-item>
        <v-list-item>
          <v-list-item-content>Refresh</v-list-item-content>
        </v-list-item>

      </v-list-group>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>far fa-question-circle</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Aide</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list-item>
        <v-list-item-icon>
          <v-icon small>fas fa-sign-out-alt</v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <router-link to="/logout"><v-list-item-title>Deconnexion</v-list-item-title></router-link>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
  <v-app-bar app color="primary">
    <v-app-bar-nav-icon class="white--text" @click="drawer = !drawer"></v-app-bar-nav-icon>

    <v-toolbar-title class="white--text">{{agence_principale}}</v-toolbar-title>
  </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "Drawer",
  data: () => ({
    drawer:true,
    nom: null,
    nego_img: null,
    agence_principale:null,
    rang:null,
  }),
  mounted() {
    this.nom = this.$store.getters["auth/currentNom"];
    this.rang = this.$store.getters["auth/currentRang"];
    this.prenom = this.$store.getters["auth/currentPrenom"];
    this.nego_img = process.env.VUE_APP_IMG_URL + 'photosUsers/'+ this.$store.getters["auth/currentImgNego"];
    this.agence_principale = this.$store.getters["auth/currentAgence"];
  }
}
</script>
