<template>
    <CardsRecherche/>
</template>

<script>
// @ is an alias to /src
import CardsRecherche from '@/components/CardsRecherches.vue'

export default {
  name: 'ListeRecherche',
  components: {
    CardsRecherche
  }
}
</script>
