<template>
  <div>
  <Drawer></Drawer>
    <router-view></router-view>
  </div>
</template>

<script>
import Drawer from "@/components/Drawer";
export default {
name: "DashBoard",
  components: {Drawer}
}
</script>

<style scoped>

</style>